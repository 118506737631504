import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        language: 'en'
    },
    mutations: {
        SET_LANGUAGE: (state, val) => {
            state.language = val
            const metas = [document.querySelector('meta[name="keywords"]'), document.querySelector('meta[name="description"]')]
            metas.forEach(meta => {
                meta.content = val === 'en' ? 'Virtual production' : '虚拟制作'
            })
            document.querySelector('title').innerHTML = val === 'en' ? 'Virtual production' : '虚拟制作'
            document.querySelector('html').lang = val === 'en' ? 'en' : ''
        }
    },
    actions: {
    },
    modules: {
    },
    getters
})
