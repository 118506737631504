import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.css'
// import './utils/rem'
// import 'normalize.css/normalize.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'

import { VueLvjs } from 'vue-lvjs' // lvjs for vue
import 'vue-lvjs/dist/vue-lvjs.css'

Vue.use(ElementUI, { size: 'mini' })
Vue.use(ElementUI, { locale })

Vue.use(VueLvjs)

Vue.config.productionTip = false // lang i18n

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
